
.block {
  display: block !important;
}

.none {
  display: none !important;
}

.inline-block {
  display: inline-block !important;
}

.inline {
  display: inline !important;
}

.inline-flex {
  display: inline-flex !important;
}

.grid {
  display: grid !important;
}

.flex {
  display: flex !important;
}

@each $break, $break-value in $grid-breakpoints-mf {

  @include respondMf($break) {
    .#{$break}-block {
      display: block !important;
    }

    .#{$break}-none {
      display: none !important;
    }

    .#{$break}-inline-block {
      display: inline-block !important;
    }

    .#{$break}-inline {
      display: inline !important;
    }

    .#{$break}-inline-flex {
      display: inline-flex !important;
    }

    .#{$break}-grid {
      display: grid !important;
    }

    .#{$break}-flex {
      display: flex !important;;
    }
  }
}
