// H1


// H2
.content--h2-regular h2 {
  font-family: $font-family-1;
  font-weight: 400;
}

.content--h2-semibold h2 {
  font-family: $font-family-1;
  font-weight: 600;
}

.content--h2-sm-margin h2 {
  margin-bottom: 0.8rem;
}

.content--h2-lg h2 {
  font-size: 1.8rem;
  line-height: 1.21;
  
  @include respondMf(md) {
    font-size: 2.2rem;
  }
  
  @include respondMf(lg) {
    font-size: 2.8rem;
  }
}

.content--h2-md h2 {
  font-size: 1.8rem;
  line-height: 1.21;

  @include respondMf(md) {
    font-size: 2.2rem;
  }

  @include respondMf(lg) {
    font-size: 2.6rem;
  }
}

// H3
.content--h3-v2 h3 {
  margin-bottom: 0;
  font-family: $font-family-1;
  font-size: 1.6rem;
  line-height: 1.4;
  text-transform: uppercase;
  
  @include respondMf(md) {
    font-size: 2rem;
  }
  
  @include respondMf(lg) {
    font-size: 2.4rem;
  }
}

.content--h3-v2-sm h3 {
  margin-bottom: 1rem;
  font-family: $font-family-1;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.4;
  text-transform: uppercase;
  
  @include respondMf(md) {
    font-size: 1.6rem;
  }
  
  @include respondMf(lg) {
    font-size: 1.8rem;
  }
}

// H4


// H5


// H6


// paragraph, lists
.content--p-sm p {
  font-size: 1.2rem;
}

.content--p-md p {
  font-size: 1.3rem;
  line-height: 2;

  @include respondMf(md) {
    font-size: 1.5rem;
  }

  @include respondMf(lg) {
    font-size: 1.6rem;
  }
}

.content--p-lg p {
  font-size: 1.4rem;
  line-height: 1.22;

  @include respondMf(md) {
    font-size: 1.5rem;
  }

  @include respondMf(lg) {
    font-size: 1.8rem;
  }
}

.content--p-xlg p {
  font-size: 1.6rem;
  line-height: 1.4;
  
  @include respondMf(lg) {
    font-size: 2rem;
  }
  
  @include respondMf(xsDesktop) {
    margin-bottom: 2.5rem;
    font-size: 2.4rem;  
  }
}

.content--p-lg-lh p {
  line-height: 1.77;
}

.content--p-md-sm-lh p {
  line-height: 1.5;
}

.content--p-black p {
  color: #000;
}

.content--p-fw-500 p {
  font-weight: 500;
}

// others
.content--a-td-none a {
  text-decoration: none;
  
  &:hover {
    text-decoration: underline;
  }
}

.content--ul-v2 ul {
  max-width: 100%;
  justify-content: flex-start;
  li {
    display: block;
    @include setFlexWidth(100%);
    margin: .3rem 3rem;
    padding-left: 1.6rem;
  }
  li::before {
    width: .6rem;
    height: .6rem;
  }
  a {
    @include setLinkColors(#fff, rgba(#fff,.6));
    text-decoration: none;
    font-size: 1.4rem;

    &:hover {
      text-decoration: underline;
    }
  }
}

// tables

// colors
.content--c-white {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  li {
    color: #fff;
  }
}
.content--c-black {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  li {
    color: #000;
  }
}
