// headings
.content h1,
.content h2,
.content h3,
.content h4,
.content h5,
.content h6 {
  
}

.content--without-bottom-margin {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ul,
  li {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

// H1
.content h1 {
  margin: 0 0 2rem;
  color: $color-1;
  font-size: 2rem;
  line-height: 1.22;
  
  @include respondMf(md) {
    font-size: 2.4rem;
  }
  
  @include respondMf(lg) {
    margin-bottom: 3rem;
    font-size: 3.2rem;
  }
}

// H2
.content h2 {
  margin: 0 0 1.5rem;
  color: $color-1;
  font-family: $font-family-2;
  font-size: 1.6rem;
  line-height: 1.4;
  
  @include respondMf(md) {
    font-size: 2rem;
  }
  
  @include respondMf(lg) {
    margin-bottom: 2.5rem;
    font-size: 2.4rem;
  }
}

// H3
.content h3 {
  margin: 0 0 1.5rem;
  color: $color-1;
  font-family: $font-family-2;
  font-size: 1.6rem;
  
  @include respondMf(md) {
    font-size: 1.7rem;
  }
  
  @include respondMf(lg) {
    margin-bottom: 2.5rem;
    font-size: 1.8rem;
  }
}

// H4
.content h4 {

}

// H5
.content h5 {

}

// H6
.content h6 {

}

// paragraph, lists
.content p {
  margin: 0 0 2rem;
  color: $color-2;
  font-size: 1.2rem;
  line-height: 1.65;

  @include respondMf(md) {
    font-size: 1.3rem;
  }

  @include respondMf(lg) {
    font-size: 1.4rem;
  }
}

.content ul,
.content ol {
  @include resetList();
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -0.5rem -2rem;
  
  @include respondMf(lg) {
    margin: -1rem -3rem;
  }
}

.content ul li,
.content ol li {
  position: relative;
  padding-left: 2rem;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.22;
  margin: 0.5rem 2rem;
  
  @include respondMf(md) {
    padding-left: 2.2rem;
    font-size: 1.5rem;
  }
  
  @include respondMf(lg) {
    font-size: 1.8rem;
    margin: 1rem 3rem;
  }
}

.content ul li::before {
  content: '';
  position: absolute;
  top: 0.9rem;
  left: 0;
  transform: translateY(-50%);
  width: 1rem;
  height: 1rem;
  background-color: #fff;

  @include respondMf(md) {
    top: 1rem;
    width: 1.2rem;
    height: 1.2rem;
  }
}

.content ol li::before {

}

// others
.content p small {

}

.content blockquote {

}

.content p a {

}

// tables
.content table {
  
}
