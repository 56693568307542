.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.zi-5 {
  z-index: 5;
}

.badge {
  position: absolute;
  top: 1.4rem;
  right: 1rem;
  background-color: #8b0000;
  padding: .2rem;
  font-size: 1rem;
  color: #fff;
}

.wb-ba {
  word-break: break-all;
}

.tablet-min-100vh {
  @media only screen and (min-width: 768px) and (max-width: 1279px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 55vh;
  }
}