
.ta-c {
  text-align: center !important;
}

.ta-l {
  text-align: left !important;
}

.ta-r {
  text-align: right !important;
}

@each $break, $break-value in $grid-breakpoints-mf {

  @include respondMf($break) {
    .#{$break}-ta-c {
      text-align: center !important;
    }

    .#{$break}-ta-l {
      text-align: left !important;
    }

    .#{$break}-ta-r {
      text-align: right !important;
    }
  }
}
