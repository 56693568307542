$logo-width: 38.4rem;
$logo-height: 8.6rem;

.menu-container {
  @include setTransition(0.25, transform, background-color, box-shadow);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}

/*
.menu-container--is-scrolled {
  transform: translateY(calc(-101% - 1.5rem));
}

.menu-container--is-scrolled-top {
  transform: none;
  box-shadow: 0 0.4rem 0.4rem rgba(#000, 0.15);
}
 */

.menu {
  display: flex;
  margin-left: -2rem;

  @include respondMf(md) {
    margin-left: -6rem;
  }

  @media only screen and (min-width: 1170px) {
    margin-left: calc((-100vw + 117rem) / 2 - 6rem);
  }

  @include respondMf(xlg) {
    margin-left: calc((-100vw + 117rem) / 2 - 2rem);
  }
}

.menu__logo-wrapper {
  position: relative;
  z-index: 10;
  margin: 0 -15% -1.5rem 0;

  @include respondMf(lg) {
    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      height: 5.4rem;
      width: 4rem;
      background-color: #fff;
    }
  }
}

.menu__logo-container {
  filter: drop-shadow(0 0.4rem 0.4rem rgba(#000, 0.15));
}

.menu__logo-link {
  display: block;
  padding: 1rem 3rem 1rem 1.5rem;
  background-color: #fff;
  clip-path: polygon(0 0,100% 0,85% 100%,0 100%);

  @include respondMf(lg) {
    padding: 2rem 3rem 2rem 0;
  }

  @include respondMf(xsDesktop) {
    padding: 2rem 8rem 1.5rem 4.5rem;
  }
}

.menu__logo {
  @include setObjectFit(contain);
  width: $logo-width * 0.5;
  height: $logo-height * 0.5;

  @include respondMf(md) {
    width: $logo-width * 0.6;
    height: $logo-height * 0.6;
  }

  @include respondMf(lg) {
    width: $logo-width;
    height: $logo-height;
  }
}

.menu__links {
  position: relative;
  z-index: 5;
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  padding-right: 2rem;
  margin-right: -2rem;
  background-color: $color-1;

  @include respondMf(md) {
    padding-right: 6rem;
    margin-right: -6rem;
  }

  @include respondMf(lg) {
    padding-right: 0;
    margin-right: 0;
    display: block;
  }
}
