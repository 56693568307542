.service-thumb {
  height: 100%;
  background-color: #fff;
  box-shadow: 0 0 1rem rgba(#000, 0.15);
}

.service-thumb__img-wrapper {
  @include aspectRatio(360, 215);
  position: relative;
  display: block;
}

.service-thumb__img {
  @include setElementAbsolute();
  @include setObjectFit(cover);
}

.service-thumb__content {
  padding: 3rem 2rem 4rem;
}
