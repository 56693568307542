.socials {
  @include resetList();
  display: flex;
  flex-wrap: wrap;
  margin: -0.7rem;

  &--sm {
    margin: -0.5rem;
  }
}

.socials__link {
  @include setFlexWidth(2.3rem);
  @include setTransition(0.25, opacity);
  position: relative;
  display: inline-block;
  height: 2.3rem;
  margin: 0.7rem;
  text-decoration: none;

  &:hover {
    opacity: 0.7;
  }

  .socials--sm & {
    @include setFlexWidth(1.6rem);
    height: 1.6rem;
    margin: 0.5rem;
  }
}

.socials__icon {
  @include setElementAbsolute();
  
  .socials--white & {
    filter: brightness(0) invert(1);
  }
}