.header {
  position: relative;
  padding: 6rem 0 9rem;

  @include respondMf(md) {
    padding: 10rem 0 15rem;
  }

  @include respondMf(lg) {
    padding: 15rem 0 20rem;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 100rem;
    height: 100%;
    background: linear-gradient(90deg, #000 11%, rgba(#000, 0.7) 88%);
    opacity: 0.3;

    @include respondMf(md) {
      background: linear-gradient(90deg, #000 11%, transparent 88%);
    }
  }
}

.header--sm {
  padding: 4rem 0;

  @include respondMf(md) {
    padding: 10.4rem 0;
  }

  h1 {
    margin: 0;
    font-family: $font-family-2;
    font-size: 2rem;
    line-height: 1.2;
    @include respondMf(md) {
      font-size: 2.8rem;
    }
  }

  &::after {
    display: none;
  }
}

.header__inner {
  position: relative;
  z-index: 5;
}

.header__bg {
  @include setElementAbsolute();
  @include setObjectFit(cover);
}
