.container {
  padding: 0 2rem;
  max-width: 118rem;

  @include respondMf(md) {
    padding: 0 6rem;
  }

  @include respondMf(xlg) {
    padding: 0 2rem;
  }

  &--lg {
    max-width: 124rem;
  }
}

.row {
  margin: 0 -1rem;

  @include respondMf(md) {
    margin: 0 -1.5rem;
  }
}

.row--sm-gutter {
  margin: 0 -1rem;

  > .col,
  > [class^="col-"] {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}

.row--mobile-sm-gutter {
  margin: 0 -0.5rem;

  @include respondMf(lg) {
    margin: 0 -1.5rem;
  }

  > .col,
  > [class^="col-"] {
    padding-right: 0.5rem;
    padding-left: 0.5rem;

    @include respondMf(lg) {
      padding-right: 1.5rem;
      padding-left: 1.5rem;
    }
  }
}

.row--center {
  justify-content: center;
}

.row--v-center {
  align-items: center;
}

.col,
[class^="col-"] {
  box-sizing: border-box;
  padding-right: 1rem;
  padding-left: 1rem;
  
  @include respondMf(md) {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
}

.col--md-2-4 {
  @include respondMf(md) {
    @include setFlexWidth(percentage(2.4/12))
  }
}
