.footer {
  position: relative;
  background-color: #1b1b1b;

  /*
  &::after {
    @include setElementAbsolute();
    content: '';
    background-color: $color-3;
    opacity: 0.75;
  }
   */
}

.footer__bg {
  @include setElementAbsolute();
  @include setObjectFit(cover);
}

.footer__logo {
  margin: 0 0 2rem;

  @include respondMf(md) {
    margin: 0 0 4rem;
  }
}

.footer__top {
  position: relative;
  z-index: 5;
  padding: 4.5rem 0 5.5rem;

  @include respondMf(md) {
    padding: 5.5rem 0 6.5rem;
  }

  @include respondMf(lg) {
    padding: 6.5rem 0 7.5rem;
  }
}

.footer__bottom {
  position: relative;
  z-index: 5;
  padding: 2rem 0 3rem;

  @include respondMf(md) {
    padding: 2.5rem 0 4rem;
  }
}

.footer__divider {
  position: relative;
  z-index: 5;
  height: 0.1rem;
  background-color: #fff;
}
