.skew-img {
  min-height: 15rem;
}

.skew-img--lg {
  @include respondMf(lgDesktop) {
    min-height: 55rem;
  }
}

.skew-img__inner {
  position: absolute;
  top: 0;
  width: calc(100% + 3rem);
  height: 100%;
  overflow: hidden;
  
  @include respondMf(md) {
    width: calc(100% + 6rem);
  }
  
  @include respondMf(xlg) {
    width: calc(100% + 2rem);
  }

  @media only screen and (min-width: 1170px) {
    width: calc(100% + (100vw - 117rem) / 2);
  }

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 5rem;
    background: center / 100% 100%;
    background-image: url('#{$path-image}/layout/triangle.svg');
    
    @include respondMf(md) {
      top: 0;
      bottom: auto;
      height: 100%;
    }
  }

  .skew-img--right & {
    left: -2rem;

    @include respondMf(md) {
      right: -4.5rem;
      left: auto;
    }

    @include respondMf(xlg) {
      right: -2rem;
    }
  
    @media only screen and (min-width: 1170px) {
      right: calc((-100vw + 117rem) / 2);
    }

    &::after {
      top: -0.1rem;
      left: -0.1rem;
      transform: scale(-1, -1);

      @include respondMf(md) {
        width: 10rem;
      }
    }
  }

  .skew-img--left & {
    left: -2rem;

    @include respondMf(md) {
      left: -6rem;
    }

    @include respondMf(xlg) {
      left: -2rem;
    }
  
    @media only screen and (min-width: 1170px) {
      left: calc((-100vw + 117rem) / 2);
    }

    &::after {
      bottom: -0.1rem;
      right: -0.1rem;

      @include respondMf(md) {
        width: 25.6rem;
      }
    }
  }

  .skew-img--right & {
    right: -2rem;

    @include respondMf(md) {
      right: -6rem;
    }

    @include respondMf(xlg) {
      right: -2rem;
    }

    @media only screen and (min-width: 1170px) {
      right: calc((-100vw + 117rem) / 2);
    }

    &::after {
      bottom: -0.1rem;
      left: -0.1rem;

      @include respondMf(md) {
        width: 25.6rem;
      }
    }
  }

  .skew-img--straight & {
    &::after {

      @include respondMf(md) {
        width: 12rem;
      }
    }
  }

  img,
  video {
    @include setElementAbsolute();
    @include setObjectFit(cover, center 75%);
  }
}