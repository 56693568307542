.section {
  padding: 5rem 0;

  @include respondMf(md) {
    padding: 6rem 0 6.5rem;
  }

  @include respondMf(lg) {
    padding: 7rem 0 8rem;
  }
}

.sub-page ul{
  display: block;
}

.sub-page ul li{
  font-size: 14px;
  font-weight: 300;
}

.sub-page ul li::before {
  content: "";
  position: absolute;
  top: 8px;
  left: 8px;
  transform: translateY(-50%);
  width: 5px;
  height: 5px;
  background-color: #e42228;
}

.sub-page-car .content--h2-md h2  {
  text-transform: uppercase;
}

.sub-page-car .content--p-xlg p{
  font-size: 15px;
}

.section__bg {
  @include setElementAbsolute();
  @include setObjectFit(cover, center center)
}