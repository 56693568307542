.form-control {
  @include setTransition(0.25, border-color);
  display: block;
  width: 100%;
  padding: 1.1em 1.5em;
  font-family: $font-family-1;
  border: 0.1rem solid $color-6;
  border-radius: 0.5rem;
  color: $color-2;
  font-size: 1.2rem;
  line-height: 1.65;

  @include respondMf(md) {
    font-size: 1.3rem;
  }

  @include respondMf(lg) {
    font-size: 1.4rem;
  }

  .form-container--error & {
    border-color: $color-1;
  }
}

textarea.form-control {
  resize: none;
}
