// flex width (flex-w)
.w-flex-100p {
  @include setFlexWidth(100%);
}

// width (w)

// max width (max-w)
.max-w-230 {
  max-width: 23rem;
}

.max-w-315 {
  max-width: 31.5rem;
}

.max-w-440 {
  max-width: 44rem;
}

.max-w-930 {
  max-width: 93rem;
}

.xs-max-w-500 {
  @include respondMf(xs) {
    max-width: 50rem;
  }
}

.md-max-w-100p {
  @include respondMf(md) {
    max-width: 100%;
  }
}

// height (h)
.h-100p {
  height: 100%;
}

// max height (max-h)

// sizes (width x height)

// object fits
.img-40x40-of-cn {
  @include aspectRatio();
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 4rem * 0.8;

  @include respondMf(lg) {
    max-width: 4rem;
  }

  img {
    @include setElementAbsolute();
    @include setObjectFit(contain);
  }
}

.img-45x45-of-cn {
  @include aspectRatio();
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 4.5rem * 0.8;

  @include respondMf(lg) {
    max-width: 4.5rem;
  }

  img {
    @include setElementAbsolute();
    @include setObjectFit(contain);
  }
}

.img-50x50-of-cn {
  @include aspectRatio();
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 5rem * 0.7;

  @include respondMf(lg) {
    max-width: 5rem;
  }

  img {
    @include setElementAbsolute();
    @include setObjectFit(contain);
  }
}

.img-55x55-of-cn {
  @include aspectRatio();
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 5.5rem * 0.7;

  @include respondMf(lg) {
    max-width: 5.5rem;
  }

  img {
    @include setElementAbsolute();
    @include setObjectFit(contain);
  }
}

.img-100x80-of-cn {
  @include aspectRatio(100, 80);
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 10rem * 0.7;

  @include respondMf(lg) {
    max-width: 10rem;
  }

  img {
    @include setElementAbsolute();
    @include setObjectFit(contain);
  }
}
