
/**
** You can't touch this. More information on: https://www.youtube.com/watch?v=otCpCn0l4Wo&feature=youtu.be&t=14
** Make customization in grid-customization.
 */

.container {
  width: 100%;
  max-width: 128rem;
  margin: 0 auto;
  padding: 0 2rem;
}

.container-full {
  max-width: 100%;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -2rem;
}

.col,
[class^="col-"] {
  flex: 0 0 100%;
  position: relative;
  width: 100%;
  max-width: 100%;
  min-height: 1px;
  padding-right: 2rem;
  padding-left: 2rem;
}

@for $i from 1 through 12 {
  .col--#{$i} {
    flex: 0 0 percentage($i/12);
    width: percentage($i/12);
    max-width: percentage($i/12);
  }
}

@for $i from 0 through 12 {
  .col--right-#{$i} {
    margin-right: percentage($i/12);
  }
}

@for $i from 0 through 12 {
  .col--left-#{$i} {
    margin-left: percentage($i/12);
  }
}

.col--auto {
  margin-left: auto;
  margin-right: auto;
}

@each $break, $break-value in $grid-breakpoints-mf {
  @include respondMf($break) {
    @for $i from 1 through 12 {
      .col--#{$break}-#{$i} {
        flex: 0 0 percentage($i/12);
        width: percentage($i/12);
        max-width: percentage($i/12);
      }
    }
    
    @for $i from 0 through 12 {
      .col--#{$break}-right-#{$i} {
        margin-right: percentage($i/12);
      }
    }
    
    @for $i from 0 through 12 {
      .col--#{$break}-left-#{$i} {
        margin-left: percentage($i/12);
      }
    }

    .col--#{$break}-auto {
      margin-left: auto;
      margin-right: auto;
    }
  }
}
