html {
  font-size: 62.5%; // 1rem = 10px
}

body {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
  overflow-x: hidden;
  max-width: 100vw;
  font-family: $font-family-1;
  padding-top: 5rem;

  @include respondMf(md) {
    padding-top: 5.9rem;
  }

  @include respondMf(lg) {
    padding-top: 11.3rem;
  }

  @include respondMf(xsDesktop) {
    padding-top: 10.8rem;
  }
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

input:focus,
button:focus,
textarea:focus {
  outline: none;
}

textarea,
input,
input[type="text"],
input[type="password"],
input[type="button"],
input[type="submit"] {
  -webkit-appearance: none;
}

input[type="submit"] {
  cursor: pointer;
}

a {
  @include setLinkColors($color-1, $color-1); // @todo
  @include setTransition(0.25, color);
}
a:hover, a:link, a:visited {
  text-decoration: none;
}
img {
  max-width: 100%;
}

#google-map {
  width: 100%;
  height: 100%;
}