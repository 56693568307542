.lg-p-b-110 {
  @include respondMf(lg) {
    padding-bottom: 11rem !important;
  }
}

.lg-p-b-170 {
  @include respondMf(lg) {
    padding-bottom: 17rem !important;
  }
}

.xsDesktop-min-h-500 {
  @include respondMf(xsDesktop) {
    min-height: 50rem;
  }
}