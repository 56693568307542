.submenu {
  @include resetList();
  @include setFlexWidth(100%);
  @include setTransition(0.25, transform, opacity);
  transform: translateY(-1rem);
  max-height: 0;
  overflow: hidden;
  border-bottom: 0.1rem solid rgba(#fff, 0.3);
  opacity: 0;
  order: 1;
  
  @include respondMf(lg) {
    position: absolute;
    top: 100%;
    width: 23.5rem;
    background-color: #fff;
    box-shadow: 0 0 2rem rgba(#000, 0.25);
    border-bottom: .3rem solid #8b0000;
  }

  .nav__item--submenu-opened & {
    transform: none;
    max-height: unset;
    opacity: 1;

    @include respondMf(lg) {
      transform: translateY(-1rem);
      max-height: 0;
      opacity: 0;
    }
  }

  .nav__item:hover & {
    @include respondMf(lg) {
      transform: none;
      max-height: unset;
      opacity: 1;
    }
  }
}

.submenu__link {
  @include setLinkColors(#fff, rgba(#fff, 0.7));
  @include setTransition(0.25, padding, color);
  position: relative;
  display: block;
  padding: 0 2rem;
  border-bottom: 0.1rem solid rgba(#fff, 0.3);
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.25;
  text-decoration: none;
  text-transform: uppercase;
  
  @include respondMf(lg) {
    @include setLinkColors($color-2, $color-1);
    border-bottom: .1rem dotted #151515;
  }

  .submenu__item:last-child & {
    border: 0;
  }
  
  .nav__item--submenu-opened &,
  .nav__item:hover & {
    padding: 1.5rem 2rem;
  }
}