.additional-service-thumb {
  height: 100%;
  box-shadow: 0 0 1rem rgba(0,0,0,.15);
  background-color: #fff;

  @include respondMf(lg) {
    display: flex;
    align-items: center;
  }
}

.additional-service-thumb__img-wrapper {
  @include aspectRatio(265, 130);
  position: relative;
  display: block;
  
  @include respondMf(lg) {
    @include aspectRatio(265, 195);
    @include setFlexWidth(20rem);
    min-height: 100%;
  }
  
  @include respondMf(xsDesktop) {
    @include setFlexWidth(26.5rem);
  }

  &::before {
    content: '';
    position: absolute;
    z-index: 5;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 4rem;
    background: center / 100% 100%;
    background-image: url('#{$path-image}/layout/triangle.svg');
    
    @include respondMf(lg) {
      width: 7rem;
      height: 100%;
    }
  }
}

.additional-service-thumb__img {
  @include setElementAbsolute();
  @include setObjectFit(cover);
}

.additional-service-thumb__content {
  padding: 2rem 1.5rem 3rem;

  @include respondMf(lg) {
    @include setFlexWidth(calc(100% - 20rem));
    padding-top: 3rem;
  }

  @include respondMf(xsDesktop) {
    @include setFlexWidth(calc(100% - 26.5rem));
  }
}