.arrow-divider {
  &::after {
    content: '';
    position: absolute;
    top: 3.5rem;
    right: 0;
    transform: translateX(50%);
    display: none;
    width: 3rem * 0.8;
    height: 2.5rem * 0.8;
    background: center / contain no-repeat;
    background-image: url('#{$path-image}/icons/arrow-divider-right.svg');

    @include respondMf(lg) {
      top: 4.5rem;
      width: 3rem;
      height: 2.5rem;
    }
  }

  &.col--6 {
    &:nth-child(2n - 1)::after {
      display: block;
    }
  }

  &.col--md-3 {
    @include respondMf(md) {
      &:nth-child(4n - 1)::after,
      &:nth-child(3n - 1)::after,
      &:nth-child(2n - 1)::after {
        display: block;
      }
    }
  }
}