
/*
** One file to rule them all,
** One file to find Them,
** One file to bring them all,
** And in the Sass way merge them.
**
 */

@import "~aos/src/sass/aos";
@import "~swiper/swiper";

// abstract
@import 'abstract/vars';
@import 'abstract/fonts';
@import 'abstract/mixins';
@import 'abstract/animations';

// vendors
@import 'vendors/normalize';
@import 'vendors/reboot';

// base
@import 'base/init';
@import 'base/a11y';
@import 'base/grid';
@import 'base/grid-customization';

// typo
@import 'typo/base';
@import 'typo/specific';

// layout
@import 'layout/header';
@import 'layout/menu';
@import 'layout/nav';
@import 'layout/submenu';
@import 'layout/footer';
@import 'layout/section';
@import 'layout/we-have-cookies';

// form
@import 'form/form-container';
@import 'form/form-control';
@import 'form/form-label';
@import 'form/form-submit';

// components
@import 'components/btn';
@import 'components/contact-link';
@import 'components/socials';
@import 'components/skew-img';
@import 'components/arrow-divider';
@import 'components/divider';
@import 'components/car-slider';
@import 'components/slider-navigation';
@import 'components/category-thumb';
@import 'components/service-thumb';
@import 'components/additional-service-thumb';

// utilities
@import 'utilities/display';
@import 'utilities/flexbox';
@import 'utilities/generated-margins-paddings';
@import 'utilities/specific-margins-paddings';
@import 'utilities/alignment';
@import 'utilities/sizes';
@import 'utilities/colors';
@import 'utilities/helpers';

