.form-label {
  @include setTransition(0.25, color);
  color: $color-2;
  font-size: 1.4rem;
  line-height: 1.65;

  @include respondMf(md) {
    font-size: 1.5rem;
  }

  @include respondMf(lg) {
    font-size: 1.6rem;
  }

  .form-container--error & {
    color: $color-1;
  }
}
