.car-slider {
  padding: 2rem 0 0;
  margin: 0 -2rem;

  @include respondMf(md) {
    margin: 0;
  }
}

.car-slider__slide {
  @include setTransition(0.25, transform, opacity);
  transform: scale(0.8, 0.8) translateX(97%);
  width: 26rem;
  border-radius: 0.2rem;
  box-shadow: 0 0 2rem rgba(#000, 0.25);
  opacity: 0;
  
  @include respondMf(xs) {
    width: 30rem;
  }
  
  @include respondMf(md) {
    width: 50rem;
  }
  
  @include respondMf(lg) {
    width: 70rem;
  }
  
  @include respondMf(xsDesktop) {
    transform: scale(0.8, 0.8) translateX(77%);
    width: 75rem;
  }

  &.swiper-slide-active ~ & {
    transform: scale(0.8, 0.8) translateX(-97%);

    @include respondMf(xsDesktop) {
      transform: scale(0.8, 0.8) translateX(-77%);
    }
  }
  
  &.swiper-slide-prev {
    transform: scale(0.8, 0.8) translateX(97%);
    opacity: 0.2;

    @include respondMf(xsDesktop) {
      transform: scale(0.8, 0.8) translateX(77%);
    }
  }
  
  &.swiper-slide-next {
    transform: scale(0.8, 0.8) translateX(-97%);
    opacity: 0.2;

    @include respondMf(xsDesktop) {
      transform: scale(0.8, 0.8) translateX(-77%);
    }
  }

  &.swiper-slide-active {
    z-index: 5;
    transform: none;
    opacity: 1;
  }
}

.car-slider__img {
  @include aspectRatio(750, 490);
  position: relative;
  display: block;

  img {
    @include setElementAbsolute();
    @include setObjectFit(cover);
  }
}

.car-slider__main {
  padding: 2.5rem 2rem 3rem;
  background-color: #fff;

  @include respondMf(md) {
    padding: 3rem 3rem 4rem;
  }

  @include respondMf(lg) {
    padding: 3.5rem 5rem 4.5rem;
  }
}