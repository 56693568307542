
.nav-toggle {
  display: none;
}

.nav-toggle-button {
  position: relative;
  z-index: 1010;
  display: block;
  height: 3rem;
  width: 3rem;
  margin-left: auto;
  $toggle-color: str-replace('#fff', '#', '%23');
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$toggle-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  color: transparent;
  cursor: pointer;

  @include respondMf(lg) {
    display: none;
  }

  .nav-toggle:checked + & {
    $toggle-color: str-replace('#fff', '#', '%23');
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' %3F%3E%3Csvg id='Outlined' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Ctitle/%3E%3Cg id='Fill'%3E%3Cpolygon fill='#{$toggle-color}' points='28.71 4.71 27.29 3.29 16 14.59 4.71 3.29 3.29 4.71 14.59 16 3.29 27.29 4.71 28.71 16 17.41 27.29 28.71 28.71 27.29 17.41 16 28.71 4.71'/%3E%3C/g%3E%3C/svg%3E");
  }
}

.nav {
  @include setTransition(0.25, transform);
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  transform: translateX(100%);
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 8rem 2rem 0;
  overflow: hidden;
  background-color: rgba($color-1, .95);

  @include respondMf(md) {
    padding: 8rem 6rem 0;
  }

  @include respondMf(lg) {
    position: relative;
    transform: translateX(0);
    flex-direction: column-reverse;
    height: 100%;
    padding: 0;
    background-color: transparent;
    transition: none;
    will-change: auto;
    overflow: visible;
  }

  .nav-toggle:checked ~ & {
    transform: translateX(0);
  }
}

.nav__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 -2rem;
  padding: 1rem 1rem 5rem;
  background-color: #fff;
  box-shadow: 0 0.4rem 0.4rem rgba(#000, 0.15);

  @include respondMf(md) {
    margin: 0 -6rem;
    padding: 1rem 5rem 5rem;
  }

  @include respondMf(lg) {
    position: relative;
    flex-wrap: nowrap;
    justify-content: flex-end;
    height: 5.4rem;
    min-height: 5.4rem;
    margin: 0;
    padding: 0;
  }
}

.nav__list {
  @include resetList();
  display: block;
  width: 100%;
  max-height: 100%;
  margin-bottom: auto;
  overflow: auto;
  box-shadow: 0 1.4rem 1.4rem rgba(255, 255, 255, 0.15);
  
  @include respondMf(lg) {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    overflow: visible;
    background-color: $color-1;
  }

  > li {
    @include respondMf(lg) {
      height: 100%;
    }
  }
}

.nav__top,
.nav__list {
  @include respondMf(lg) {
    width: calc(100% + 6rem);
    padding-right: 6rem;
    margin-right: -6rem;
  }

  @media only screen and (min-width: 1170px) {
    width: calc(100% + (100vw - 117rem) / 2 + 6rem);
    padding-right: calc((100vw - 117rem) / 2 + 6rem);
    margin-right: calc((-100vw + 117rem) / 2 - 6rem);
  }

  @include respondMf(xlg) {
    width: calc(100% + (100vw - 117rem) / 2 + 2rem);
    padding-right: calc((100vw - 117rem) / 2 + 2rem);
    margin-right: calc((-100vw + 117rem) / 2 - 2rem);
  }
}

.nav__item {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-bottom: 0.1rem solid rgba(#fff, 0.3);

  @include respondMf(lg) {
    margin-right: 4rem;
    border: 0;
  }

  &:last-of-type {
    margin: 0;
  }

  &--submenu {
    &::after {
      @include setFlexWidth(0.7rem * 1.7);
      @include setTransition(0.25, transform, opacity);
      content: '';
      height: 0.4rem * 1.5;
      margin-left: 2rem;
      background: center / contain no-repeat;
      background-image: url('#{$path-image}/icons/chevron-down.svg');

      @include respondMf(lg) {
        @include setFlexWidth(0.7rem);
        height: 0.4rem;
        margin: 0 -1rem 0 0.5rem;
      }
    }
  }

  &--submenu-opened::after {
    transform: scale(1, -1);
    opacity: 0.7;

    @include respondMf(lg) {
      transform: none;
      opacity: 1;
    }
  }

  &--submenu:hover::after {
    @include respondMf(lg) {
      transform: scale(1, -1);
      opacity: 0.7;
    }
  }
}

.nav__link {
  @include setLinkColors(#fff, rgba(#fff, 0.7));
  display: inline-block;
  flex: 1 1 auto;
  padding: 1.5rem 0;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1.25;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;

  @include respondMf(lg) {
    font-size: 1.2rem;
  }
}

.nav__icon {
  @include setObjectFit(contain);
  @include setTransition(0.25, opacity);
  display: inline-block;
  width: 2rem;
  height: 1.8rem;

  @include respondMf(lg) {
    width: 1.3rem;
    height: 1.1rem;
  }

  .nav__link:hover & {
    opacity: 0.7;
  }
}
