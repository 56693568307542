.category-thumb {
  @include aspectRatio();
  position: relative;
  display: flex;
  align-items: center;
  padding: 1rem;
  border-radius: 2px;
  box-shadow: 0 0.4rem 2rem rgba(#000, 0.05);

  @media only screen and (min-width: 480px) {
    padding: 1.5rem;
  }

  @include respondMf(lg) {
    padding: 2rem;
  }
  
  &::before {
    @include setElementAbsolute();
    content: '';
    z-index: 5;
    background: linear-gradient(180deg, $color-1 27%, transparent);
    opacity: 0.8;
  }
}

.category-thumb__img {
  @include setElementAbsolute();
  @include setObjectFit(cover);
}

.category-thumb__title {
  position: relative;
  z-index: 10;
  margin: 0;
  color: #fff;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.2;
  text-shadow: 0 .4rem .4rem rgba(0, 0, 0, 0.15);
  
  @media only screen and (min-width: 480px) {
    font-size: 1.6rem;
  }
  
  @include respondMf(md) {
    font-size: 1.4rem;
  }
  
  @include respondMf(lg) {
    font-size: 1.8rem;
  }
  
  @include respondMf(xsDesktop) {
    font-size: 2.4rem;
  }
}
