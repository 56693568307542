.contact-link {
  @include setLinkColors($color-2, $color-1);
  display: inline-flex;
  align-items: center;
  font-size: 1.2rem;
  line-height: 1.65;
  text-decoration: none;

  @include respondMf(md) {
    font-size: 1.3rem;
  }

  @include respondMf(lg) {
    font-size: 1.4rem;
  }

  &::before {
    @include setFlexWidth(1.6rem);
    content: '';
    height: 1.6rem;
    margin-right: 1rem;
    background: center / contain no-repeat;
  }

  &--white {
    @include setLinkColors(#fff, rgba(#fff, 0.7));

    &::before {
      filter: brightness(0) invert(1);
    }
  }

  &--sm {
    font-size: 1.1rem;
  }
  
  &--mail:before {
    background-image: url('#{$path-image}/icons/mail.svg');
  }
  
  &--phone:before {
    background-image: url('#{$path-image}/icons/phone.svg');
  }
}