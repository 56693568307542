.btn {
  @include setLinkColors(#fff, #fff);
  @include resetBtn();
  @include setTransition(0.25, background-color);
  position: relative;
  z-index: 5;
  display: inline-block;
  padding: 0.9em 1.9em 0.9em 2.75em;
  margin-right: 3rem;
  border-radius: 0.2rem;
  color: #fff;
  font-family: $font-family-2;
  font-size: 1.2rem;
  line-height: 1.75;
  text-align: center;
  text-decoration: none;
  background-color: $color-1;
  cursor: pointer;
  
  @include respondMf(md) {
    font-size: 1.4rem;
  }
  
  @include respondMf(lg) {
    font-size: 1.6rem;
  }
  
  &::before {
    @include setElementAbsolute();
    @include setTransition(0.25, background-color);
    content: '';
    z-index: -5;
    left: 1.5rem;
    transform: skew(-25deg);
    background-color: $color-1;
    border-radius: 0.2rem;
  }

  &--short {
    padding: 0.9em .6em 0.9em 1.3em;
  }
  
  &:hover,
  &:hover::before {
    background-color: darken($color-1, 10%);
  }

  &--white {
    @include setLinkColors($color-1, $color-1);
    background-color: #fff;

    &::before {
      background-color: #fff;
    }

    &:hover,
    &:hover::before {
      background-color: lighten($color-1, 40%);
    }
  }

  &--sm {
    @include setLinkColors($color-1, darken($color-1, 15%));
    @include setTransition(0.25, border-color, color);
    padding: 0.7em 0 0.7em 1.3rem;
    margin-right: 1.7rem;
    background-color: transparent;
    border: 0.2rem solid $color-1;
    border-right: 0;
    font-size: 1rem;
    
    &::before {
      @include setTransition(0.25, box-shadow);
      left: 1rem;
      transform: skew(-20deg);
      background-color: transparent;
      box-shadow: 0.2rem 0.2rem 0 $color-1, 0.2rem -0.2rem 0 $color-1;
      border-left: 0;
    }

    &:hover,
    &:hover::before {
      background-color: transparent;
    }

    &:hover {
      border-color: darken($color-1, 15%);
    }

    &:hover::before {
      box-shadow: 0.2rem 0.2rem 0 darken($color-1, 15%), 0.2rem -0.2rem 0 darken($color-1, 15%);
    }

    .nav__top & {
      @include respondMf(lg) {
        margin-right: 0;
      }
    }
  }
}
