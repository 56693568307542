.slider-navigation {
  display: flex;
  justify-content: center;
  margin: 0 -2.1rem;
}

.slider-navigation__control {
  @include resetBtn();
  @include setFlexWidth(1.8rem * 0.8);
  @include setTransition(0.25, filter, opacity);
  position: relative;
  height: 3rem * 0.8;
  margin: 0 2.1rem;
  cursor: pointer;
  
  @include respondMf(lg) {
    @include setFlexWidth(1.8rem);
    height: 3rem;
  }

  &:hover {
    filter: brightness(0.8);
  }

  &.swiper-button-disabled {
    filter: grayscale(1);
    opacity: 0.2;
  }
}

.slider-navigation__icon {
  @include setElementAbsolute();
  @include setObjectFit(contain);
}